import React from "react"
import { Link } from "gatsby"
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton } from 'react-twitter-embed';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import LeftMenu from './leftMenu'

const Awards = () => (
  <Layout>
    <SEO title="National Student Essay Competition" />

    <div className="subpageContainer">

      <LeftMenu active="awards" />
      <div className="body">
         
        <h1 style={{marginTop: '55px'}}>Awards and Prizes</h1>


        <h3>NATIONAL PRIZES</h3>
          <p>The winner in each category (grades six-eight; grades nine-twelve; and grades university/college) will each receive a $5,000 check from the Boston Globe Foundation.</p>
          <p>The winning essayist with the highest ranking among the three categories will also receive a full four-year scholarship, current total value of $152,000, to Westminster College in Salt Lake City.</p>
          <p>Prizes will be awarded November 7, 2020 at the McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism.</p>

        <h3>POPULAR CHOICE AWARD</h3>
        <p>
        The essays submitted by students, sixth grade to university and college, were thoughtful and powerful in defense of the First Amendment and the importance of a free press in our country. We wanted to share these earnest, persuasive voices with as many of our fellow citizens, of all ages and backgrounds, as possible. To that end we invited the public, in addition to our distinguished jury, to vote on the finalist essays. The winner of the popular vote will will receive a check for $1000 from Mary Kay Lazarus Public Relations.
        </p>

        {/* <div><strong><a className="vote-link" href="https://forms.gle/QZCSCzzkgeXfZHCu7" target="_blank">Vote for the best essay here!</a></strong></div> */}

      </div>
    </div>

    

  </Layout>
)

export default Awards
